html {
  height: 100%;
}

body {
  background: #f5f5f5;
  height: 100%;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body > div {
  height: 100%;
}

.resizer-container {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

a.select-file {
  background: #fff9c4;
  border: dashed #bdbdbd;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
  margin: 1rem;
  min-width: 300px;
  color: #424242;
}

a.select-file:hover {
  text-decoration: underline;
}

a.select-file.dragging {
  background: #fdd835;
}

.color-select {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
}

.color-select > div {
  margin-right: 1rem;
}
